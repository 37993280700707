@import '~@opengov/capital-style/scss/tokens';

.header {
    font-size: $font-size-default;
    font-weight: $font-weight-semi-bold;
    margin-bottom: $unit-half;
}

.helpText {
    color: $color-fg-secondary;
    font-size: $unit-2;
    margin-bottom: $unit-3;
}

.internalBadge {
    background-color: $color-bg-tertiary;
    border-radius: $border-radius-default;
    color: $color-gray-700;
    font-size: $font-size-minimum;
    font-style: $base-font-alt;
    font-weight: $font-weight-semi-bold;
    letter-spacing: 0.2px;
    line-height: $unit-2;
    margin-left: 8px;
    padding: $unit-half $unit-1;
}

.subText {
    color: $color-fg-secondary;
    font-weight: $font-weight-regular;
}

.fieldwidth {
    width: 100%;
}
