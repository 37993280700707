@import '~@opengov/capital-style/scss/tokens';

.lineItemDetails {
    width: 100%;

    :global(.control-label) {
        font-family: $base-font-alt;
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
    }

    .unstyledButton {
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
    }

    .unstyledButton:hover {
        background: none;
    }

    .lineItemHeader {
        display: flex;
        justify-content: space-between;
        padding-bottom: $unit-2;

        .lineItemTitle {
            color: $color-fg-secondary;
            font-size: $font-size-default;
            font-weight: $font-weight-semi-bold;
        }

        .removeButton {
            color: #992f3a;
            font-weight: $font-weight-semi-bold;
        }
    }

    .mainLine {
        display: flex;
        flex-flow: row wrap;
        gap: $unit-3;

        .description {
            flex: 1 0 0;

            @media (max-width: 1280px) {
                flex: 1 100%;
            }
        }

        .quantity {
            width: 75px;
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                appearance: textfield;
            }
        }

        .total {
            text-align: right;
            width: 135px;

            .budgetCheck {
                font-size: $font-size-default;
            }
        }

        .unitOfMeasure {
            width: 103px;
        }

        .unitPrice {
            width: 200px;

            input {
                text-align: right;
            }
        }

        .discount {
            display: flex;
            width: 150px;

            :global(.react-select__control) {
                background-color: $color-bg-secondary;
                min-height: 32px;
                width: max-content;
            }

            :global(.react-select__indicators) {
                padding: 4px 8px 4px 4px;
            }

            :global(.react-select__option--is-focused) {
                color: $color-white !important;
            }

            :global(.react-select__value-container) {
                color: $color-fg-secondary;
                font-weight: $font-weight-semi-bold;
                padding: 0 0 0 8px;
            }

            .discountInput {
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
                border-color: $color-fg-border-tertiary;
                border-top-left-radius: 3px;
                border-top-right-radius: 0;
                color: $color-fg-secondary;
            }

            .discountTypeSelectLegacy {
                margin-top: 28px;
            }

            .discountTypeSelectLegacy div {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 3px;
                border-color: $color-fg-border-tertiary;
                border-left: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 3px;
                color: $color-fg-secondary;
            }

            .discountTypeSelect {
                margin-top: 20px;
            }

            .discountTypeSelect div {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 3px;
                border-color: $color-fg-border-tertiary;
                border-left: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 3px;
            }

            .removeDiscount {
                font-weight: $font-weight-regular;
            }
        }

        .checkboxItem {
            display: flex;
            justify-content: center;
            margin-top: 0;
        }
    }

    .addButton {
        color: $color-fg-secondary;
        font-weight: $font-weight-semi-bold;
    }
}
